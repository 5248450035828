@import '../../styles/inc/fonts'
@import '../../styles/inc/effects'
@import '../../styles/inc/consts'

.reis

  background: var(--white100)
  @include mailCardShadow
  border-radius: 1.2rem
  padding:1rem
  @media (min-width: $mobile)
    padding:2.5rem

  &__header
    padding: 1.5rem

    &-date
      color: var(--text500)
      font-family: 'CircularStd_Book'
      font-weight: 400
      font-style: normal
      font-size: 1.8rem
      list-height: 2rem
      letter-spacing: .007rem
      b
        color: var(--text900)
        font-family: 'CircularStd_Book'
        font-weight: 700
        font-style: normal
        font-size: 1.8rem
        list-height: 2rem
        letter-spacing: .007rem
        text-transform:  uppercase

  &__time
    display: flex
    align-items: center
    flex-direction: column
    align-items: start
    border-top: .1rem solid var(--text50)
    border-bottom: .1rem solid var(--text50)
    padding: 1rem
    margin-left: 5.5rem
    svg
      margin-right: .8rem
    &-text
      display: flex
      @include F15Book
      color: var(--text900)
      b
        color: var(--text900)
        span
          color: var(--text400)

.info
  display: flex
  margin-left: 1.5rem
  margin-top: 1.5rem


  &-avatar
    width: 2.4rem
    height: 2.4rem

    @media (min-width: $mobile)
      width: 4rem
      height: 4rem
      margin-top: 3rem

    .img
      width: 100%
      height: 100%
      object-fit: cover

  &__info
    padding-left: 3.9rem

    .infoBlock
      position: relative

      &-time
        @include F20Medium
        color: var(--text900)
        display: block

        &::after
          position: absolute
          left: -2.4rem
          top: 0
          width: 1.6rem
          height: 1.6rem
          border: 0.26rem solid var(--text100)
          border-radius: 100%
          content: ''
          background: var(--white100)

        &--line
          &::before
            position: absolute
            left: -1.8rem
            top: 1rem
            width: 3px
            height: 100%
            border-left: 3px dashed #EAEAEB
            content: ''
        sup
          color: var(--additionalRed)  

      &-locate
        @include F15Book
        color: var(--text900)
        font-weight: initial
        @media (min-width: $mobile)
          @include F20Medium  
      &__adv
        margin-top: 1.2rem
        margin-bottom: 1.2rem
        &-text  
          @include F14Book
          color: var(--text400)
          display: flex
          align-items: center        
          b
            color: var(--text900)

.warrning
  display: flex
  align-items: center
  color: var(--additionalRed)
  @include F15Book
  svg
    margin-right: .8rem
    margin-left: .1rem