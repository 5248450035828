@import '../../styles/inc/fonts'
  
.block
  padding: 1.5rem 2.5rem
  background: var(--additionalHighlight)
  border-radius: 1.2rem

  &-title
    @include F17Medium
    color: var(--text900)
  
  &__line
    display: flex
    align-items: center
    height: 4rem

    &-title
      @include F15Book
      color: var(--text400)
      margin-left: 3.5rem
      width: 100%
    
    &-price
      @include F15Book
      color: var(--text400)

  &__footer
    display: flex
    align-items: center
    justify-content: space-between
    margin-top: 1.3rem

    &-text
      @include F17Medium
      color: var(--text900)
    &-value
      @include F17Medium
      color: var(--text900)