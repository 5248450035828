@import './consts'
  
@mixin cardShadowGrey
  box-shadow: 0 3.8rem 8rem rgba(0, 0, 0, 0.013), 0 1.92375rem 3.4875rem rgba(0, 0, 0, 0.02), 0 .76rem 1.3rem rgba(0, 0, 0, 0.027), 0 .16625rem .4625rem rgba(0, 0, 0, 0.04)

@mixin cardBevel
  box-shadow: 0 .22rem .431852rem rgba(0, 0, 0, 0.113852), inset 0 0 .2rem .2rem rgba(255, 255, 255, 0.1)

@mixin bevelMorph
  box-shadow: inset .1rem -.1rem .1rem rgba(160, 123, 100, 0.6), inset -.1rem .1rem .1rem rgba(255, 255, 255, 0.3)

@mixin cardTet
  box-shadow: 0 2.7rem 5.3rem rgba(0, 0, 0, 0.29), 0 1.04rem 1.68815rem rgba(0, 0, 0, 0.176148), 0 .22rem .431852rem rgba(0, 0, 0, 0.113852), inset 0 0 2 2 rgba(255, 255, 255, 0.3)
  
  @if $blur
    backdrop-filter: blur(2.4rem)

@mixin E11
  box-shadow: 0 0 4.2rem rgba(0, 0, 0, 0.04), 0 0 .6825rem rgba(0, 0, 0, 0.08)

@mixin E22
  box-shadow: 0 .2rem 1.2rem rgba(0, 0, 0, 0.12)

@mixin inputActive
  box-shadow: 0 1.1rem 1.29rem rgba(44, 44, 44, 0.0902963), 0 .423704rem 4.10889rem rgba(51, 51, 51, 0.139704), 0 .0896296rem 1.05111rem rgba(35, 35, 35, 0.23)

@mixin brownBevel2
  box-shadow: inset .1rem -.1rem .1rem rgba(160, 123, 100, 0.2), inset -.1rem .1rem .1rem rgba(255, 255, 255, 0.1)
  
  @if $blur
    backdrop-filter: blur(3rem)

@mixin buttonGlassBevel
  box-shadow: inset .1rem -.1rem .1rem rgba(188, 120, 77, 0.4), inset -.1rem .1rem .1rem rgba(233, 155, 107, 0.58)

@mixin mailCardShadow
  box-shadow: 0 .1rem .4rem rgba(0, 0, 0, 0.08)

@mixin smallIconShadow
  box-shadow: 0 .1rem .2rem rgba(0, 0, 0, 0.09)

@mixin E23
  box-shadow: 0 .1rem .6rem rgba(0, 0, 0, 0.06)

@mixin checkedBoxShadow
  box-shadow: 0 0 .7rem rgba(142, 94, 62, 0.75), 0 0 .11375rem .1rem rgba(113, 75, 50, 0.8)

@mixin E25
  box-shadow: 0 .2rem 2.9rem rgba(123, 79, 51, 0.274815), 0 .077037rem .923704rem rgba(123, 79, 51, 0.425185), 0 .0162963rem .236296rem rgba(123, 79, 51, 0.7)

@mixin glowBrown
  box-shadow: 0 0 .7rem rgba(142, 94, 62, 0.24), 0 0 .11375rem .1rem rgba(113, 75, 50, 0.24)

@mixin brownGlow
  box-shadow: 0 0 1.6rem rgba(199, 156, 127, 0.68), 0 0 .4rem .1rem rgba(199, 156, 127, 0.33)

@mixin blackShadow
  box-shadow: 0 .4rem 1.6rem rgba(0, 0, 0, 0.27), 0 .08rem .26rem rgba(0, 0, 0, 0.54)

@mixin windowsFrameShadow
  box-shadow: 0 8.5rem 8.3rem rgba(0, 0, 0, 0.63), 0 1.7rem 1.34875rem rgba(0, 0, 0, 0.315)

@mixin inputTypeHiglight
  box-shadow: 0 0 6.7rem rgba(197, 114, 53, 0.051037), 0 0 2.13407rem rgba(197, 114, 53, 0.078963), 0 0 .545926rem rgba(197, 114, 53, 0.13), inset 0 0 .3rem .1rem #F7D2BB