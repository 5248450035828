@import '../../../../styles/inc/fonts'
@import '../../../../styles/inc/effects'

.btn
  padding: 1.6rem
  width: 100%
  border-radius: 1.2rem
  color: var(--white100)
  @include F20Bold
  transition: .3s

  &--default
    background: var(--action500)
    &:hover
      background: var(--action600)
      @include E25
    &:active
      background: var(--action700)
    &:disabled
      background: var(--action500)
      opacity: .2

  &--dark
    background: var(--text700)
    &:hover
      background: var(--text800)
      box-shadow: 0 .2rem 2.9rem rgba(0, 0, 0, 0.274815), 0 0.077037rem .923704rem rgba(0, 0, 0, 0.425185), 0 0.162963px .236296rem rgba(0, 0, 0, 0.7)
    &:active
      background: var(--text900)
    &:disabled
      background: var(--text700)
      opacity: .32

  &--outline
    background: transparent
    border: .1rem solid var(--action500)
    color: var(--action500)