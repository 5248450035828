@import '../../styles/inc/fonts'
@import '../../styles/inc/consts'
  
.details__check
  display: flex
  align-items: center
  margin-left: 1rem

  &-text
    margin-left: 1rem
    @include F15Book

.details__form
  display: grid
  grid-template-columns: 1fr 1fr
  grid-gap: 1rem
  margin: 2rem 1rem 0rem 1rem
  @media (max-width: $mobile)
    grid-template-columns: 1fr

  .grid
    display: grid
    grid-template-columns: 20rem minmax(16rem, 1fr)
    grid-gap: .5rem
