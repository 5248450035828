@import '../../styles/inc/fonts'

.title
  display: flex
  align-items: center
  position: relative

  &--white
    .title__content
      span
        background-color: var(--white100)
        &::before,
        &::after
          background-color: var(--white100)
    .title__icon
      background-color: var(--white100)

  &__content
    span
      padding-right: 1.5rem
      padding-left: 1.5rem
      margin-left: 1.5rem
      @include F17BoldAA
      color: var(--text900)
      background-color: var(--text50)
      z-index: 1
      position: relative
    &::before
      position: absolute
      top: 50%
      left: 0
      width: 100%
      height: .2rem
      background: var(--action300)
      content: ''
      transform: translateY(-50%)
      z-index: 0
    &::after
      position: absolute
      top: 50%
      left: 0
      width: 1rem
      height: .2rem
      background: var(--action300)
      content: ''
      transform: translateY(-50%)
      z-index: 0

  &__icon
    position: absolute
    right: 0
    top: 50%
    transform: translateY(-50%)
    width: 3rem
    background-color: var(--text50)
    text-align: center
