@import '../../styles/inc/fonts'
@import '../../styles/inc/effects'

.body__service
  background: var(--white100)
  border-radius: 1.2rem
  @include mailCardShadow
  margin: 0 1rem
  padding-top: 2.5rem
  display: flex
  flex-direction: column
  align-items: center
  margin-top: 1rem

  .avatar
    display: flex
    justify-content: center

  &-avatar
    position: relative

    .status
      position: absolute
      top: 0
      left: 0
      width: 2rem
      height: 2rem
      border-radius: 100%
      background: var(--green100)
      border: .4rem solid var(--white100)

    .img
      width: 8rem
      height: 8rem
      object-fit: cover
      border-radius: 100%

  &-title
    @include F20Medium
    color: var(--text900)
    margin-top: 1rem

  &-description
    @include F17Book
    color: var(--text500)
    margin-top: 1rem

  &-counter
    display: flex
    align-items: center
    margin-top: 1rem

    &-text
      max-width: 12.2rem
      height: 4.8rem
      display: flex
      justify-content: center
      align-items: center
      background: var(--text50)
      border-radius: .6rem
      margin: 0 .5rem
      @include F17Book
      color: var(--text900)
      text-align: center
      border: .2rem solid transparent
      transition: .3s

      &:focus
        transition: .3s
        background: transparent
        border: .2rem solid var(--text500)

      input

        // &:active
        //   .body__service-counter-text
        //     background: var(--white100)
        //     border: .2rem solid var(--text300)

  &-confirm
    display: flex
    align-items: center

    background: var(--text500)
    border-radius: 0 0 1.2rem 1.2rem
    padding: 2rem

    width: 100%

    p
      @include F13Bold
      color: var(--white100)
      margin-left: 1.5rem