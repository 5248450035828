@mixin flexSpaceBetween
  display: flex
  justify-content: space-between

@mixin flexAlignCenter
  display: flex
  align-items: center

@mixin flexCenter
  display: flex
  justify-content: center
  align-items: center

@mixin flexColumn
  display: flex
  flex-direction: column

@mixin backgroundCover
  background-size: cover
  background-repeat: no-repeat

@mixin hideScroll
  -ms-overflow-style: none
  scrollbar-width: none
  &::-webkit-scrollbar
    display: none