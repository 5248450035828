@import '../../../styles/inc/fonts'
@import '../../../styles/inc/effects'
@import '../../../styles/inc/mixins'
  
.item
  position: relative
  pointer-events: none

  &--error
    box-shadow: 0 0 3px 1px red
    
  &__input
    *
      cursor: pointer

  &__list
    pointer-events: all
    position: absolute
    top: 5.5rem
    width: 100%
    height: 0px
    z-index: 5
    background: var(--white100)
    overflow-y: scroll
    transition: .3s
    // @include hideScroll
    box-shadow: 0 1px 3px rgb(0 0 0 / 20%)
    z-index: 9
    &::-webkit-scrollbar-track
      background-color: var(--text100)
    &::-webkit-scrollbar-thumb
      box-shadow: inset 0 0 6px var(--text500)
    &::-webkit-scrollbar
      width: .3rem

    &--open
      height: 20rem
      transition: .3s

    &--relative
      top:0 
      position: relative

    span
      display: block
      @include F12Book
      text-align: left
      cursor: pointer
      padding-left: 1rem

      &:hover
        transition: .1s
        background: var(--action400)
        color: var(--white100)

.close
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  background: transparent
  z-index: 8
  cursor: pointer