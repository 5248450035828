@import '../../../styles/inc/fonts'
@import '../../../styles/inc/effects'
  
.selector
  display: grid
  grid-template-columns: 1fr 1fr
  grid-gap: .5rem

  *
    transition: .3s
    
  &__item
    background: var(--white100)
    @include E23
    border-radius: .6rem
    display: flex
    align-items: center
    padding: 1rem
    cursor: pointer

    &--error
      box-shadow: 0 0 3px 1px red

    &--active
      background: var(--action500)
      @include checkedBoxShadow

      .selector__item
        &-curcle
          border: .6rem solid var(--white100)
          background: var(--action500)
        &-label
          color: var(--white100)

    &-curcle
      min-width: 2rem
      min-height: 2rem
      background: var(--white100)
      border: .2rem solid var(--action300)
      border-radius: 100%
    &-label
      @include F15Book
      color: var(--text300)
      width: 100%
      text-align: center