
@import ../../styles/inc/fonts

.pcForm
  margin-top: 1rem
  &__info
    display: grid
    grid-template-columns: 1fr 1fr 1fr
    grid-gap: 1rem
  &__date
    display: grid
    grid-template-columns: 1fr 1fr 1fr
    grid-gap: 1rem
    margin-top: 1rem
    margin-bottom: 1rem
    &-gender
      height: 5.6rem
  &__bottom
    display: grid
    grid-template-columns: 1fr 1fr
    grid-gap: 3rem
    align-items: center
  &__err
    @include F15Book
    color: red
    padding-bottom: 1rem

.body__passenger
  margin-top: 4rem

  &-form
    margin: 1rem
    display: grid
    grid-template-columns: 1fr
    grid-gap: 1rem 0
    &--three
      display: grid
      grid-template-columns: 1fr 1fr 1fr
      grid-gap: 0 1rem
  &__err
    @include F14Book  
    color: red