@import '../../../../styles/inc/fonts'
@import '../../../../styles/inc/effects'

.block
  height: 5.6rem
  width: 100%
  border-radius: .4rem
  @include E23
  background-color: var(--white100)
  position: relative
  display: flex
  align-items: flex-end
  padding: 0 1.5rem

  &--error
    box-shadow: 0 0 3px 1px red

  &--dark
    background-color: var(--inputBgDef)
    @include brownBevel2

  &--error
    box-shadow: 0 0 3px 1px red
    
  &-label
    @include F15Book
    color: var(--text300)!important
    position: absolute
    top: 50%
    transform: translateY(-50%)
    pointer-events: none
    transition: .1s

    &--active
      top: 35%
      color: var(--text400)
    &--smallText
      @include F10Medium
    &--dark
      color: var(--white100)
    &--error
      box-shadow: 0 0 3px 1px red


  &-input
    height: 100%
    width: 100%
    @include F17Book
    color: var(--text900)

    &--active
      height: 65%

    &--full
      height: 100%

.bank
  position: absolute
  top: 50%
  right: 3rem
  transform: translateY(-50%)