@import '../../../../styles/inc/effects'
@import '../../../../styles/inc/mixins'
@import '../../../../styles/inc/fonts'
  
.btn
  background: var(--buttonIconBg90)
  padding: 1.5rem 0
  border-radius: 1.2rem
  box-shadow: inset .1rem -.1rem .1rem #D0824F, inset -.1rem .1rem .1rem #E99B6B
  color: var(--white100)
  transition: .3s
  
  @include flexCenter
  align-items: center

  @include F20Bold
  line-height: unset

  &:hover
    background: var(--buttonIconBg100)
    box-shadow: inset .1rem -.1rem .1rem #DD8F5D, inset -.1rem .1rem .1rem #F8B992

  &:active
    background: var(--buttonIconBgFocus)
    @include buttonGlassBevel

  &:disabled
    background: rgba(166, 110, 73, 0.2)
    box-shadow: none!important
    @include buttonGlassBevel
    opacity: .5

  &--text
    min-width: 16.6rem
  &--textIcon
    min-width: 16.6rem
    padding-left: 2rem
    padding-right: 2rem
    @include flexSpaceBetween

  &--bigText
    min-width: 12rem
  &--bigTextIcon
    min-width: 14.5rem
    padding-left: 2rem
    padding-right: 2rem
    @include flexSpaceBetween

  // 
  &--small
    min-width: 11.4rem

  &--trustPilot
    background: var(--buttonIconBg2)
    @include buttonGlassBevel
    @include F15Bold
    @include flexCenter
    svg
      margin-left: .8rem
      margin-right: .8rem
    &:hover
      background: var(--buttonIconBgHover)
    &:active
      background: var(--buttonIconBgFocus)

  &--call
    @include F15Bold
    color: var(--white100)
    svg
      margin-left: .8rem
      margin-right: .8rem