.toggle
  width: 4.7rem
  height: 2rem
  border-radius: 2.1rem
  background: var(--text100)
  position: relative
  cursor: pointer
  transition: .3s

  &--active
    background: var(--action500)
    .toggle-sphere
      left: 2.5rem
    &:hover
      background: var(--action500)!important
    

  &:hover
    background: var(--text200)

  &-sphere
    width: 2.4rem
    height: 2.4rem
    position: absolute
    left: 0
    top: -.17rem
    border-radius: 100%
    background: var(--white100)
    box-shadow: 0 .2rem .533333rem rgba(0, 0, 0, 0.15), 0 .0666667rem .0666667rem rgba(0, 0, 0, 0.08)
    transition: .3s