@import './inc/fonts'
@import './inc/effects'
@import './inc/consts'

.body
  background: var(--text50)
  width: 100%
  border-radius: 2rem

  &-title
    @include F17Medium
    color: var(--text900)
    text-align: center
    display: block
    padding: 2rem 2rem 0 2rem
    @media (min-width: $mobile)
      text-align: left

  &__mode
    display: grid
    grid-template-columns: 1fr 1fr 1fr
    grid-gap: 1rem
    margin-top: 2rem
    padding: 0 2rem

    &--1
      grid-template-columns: 1fr

    &--2
      grid-template-columns: 1fr 1fr

    &--selected
      grid-template-columns: 1fr

    &-item
      background: var(--white100)
      @include mailCardShadow
      border-radius: 1.2rem
      padding: .8rem 1rem
      transition: .2s

      &--active
        background: var(--text800)
        *
          color: var(--white100)!important
      // 
      .item
        &-title
          @include F12Medium
          color: var(--action500)
          display: block
        &-price
          @include F15Bold
          color: var(--action500)

  &__title
    margin-top: 2.5rem

  &-trip
    @include F13MediumAA
    color: var(--text500)
    padding-left: 1rem

  &__footer
    display: flex
    flex-direction: column
    align-items: center
    &-title
      @include F17Medium
      color: var(--text900)
      margin-top: 4rem
    &-desc
      @include F15Book
      color: var(--text500)
      margin-top: .5rem

  &__toggle
    display: flex
    align-items: center
    justify-content: center
    margin-top: 1.7rem

    &-text
      @include F13Bold
      margin-left: .9rem

  &__tip
    display: flex
    justify-content: center
    margin-top: 1.7rem

  &__container
    padding: 0 2rem 2rem 2rem

  &__breakdown
    display: none
    padding: 0.5rem 0
    position: sticky
    bottom: 56px
    background: var(--action100)
    z-index:-1
    transition: 1s  
    &--show
      display: block
      z-index: 10
      transition: 1s

  &__header
    display: flex
    align-items: center
    padding-top: 2rem

    &-info
      display: flex
      flex-direction: column
      align-items: center
      width: 100%
    &-title
      @include F17Medium
      color: var(--text900)
      display: block
    &-desc
      @include F12Book
      color: var(--text400)
    &-back
      margin-left: 1rem

  &__tip
    margin: 0 1rem

  &__protection
    margin: 1rem
    padding-bottom: 4rem
    // &-block
    &-header
      background: var(--text500)
      border-radius: 1.2rem 1.2rem 0 0
      display: flex
      align-items: center
      padding: 1.5rem
      span
        @include F13Bold
        color: var(--white100)
        margin-left: .9rem

    &-body
      background: var(--white100)
      box-shadow: 0 0 8rem rgba(0, 0, 0, 0.07), 0 0 2.54815rem rgba(0, 0, 0, 0.0425185), 0 0 .651852rem rgba(0, 0, 0, 0.0274815)
      border-radius: 0 0 1.2rem 1.2rem

      .top
        display: flex
        justify-content: center
        text-align: center
        padding: 1.5rem 1.5rem 1.5rem 1.5rem
        text-align: center
        span
          @include F24Medium
          color: var(--text900)
        p
          @include F15Book
          color: var(--text500)
        .info
          margin-right: 2.6rem

      .shield
        text-align: center
      .title
        @include F24Medium
        color: var(--text900)
        text-align: center
      .description
        @include F15Book
        color: var(--text900)
        text-align: center
        margin-bottom: 1.6rem
      .btns
        display: grid
        grid-template-columns: 1fr 1fr
        grid-gap: 1rem
        padding: 2rem 2.5rem 2.5rem 2.5rem
        button
          padding: .9rem
      .close
        height: fit-content
      .protectOff
        display: flex
        align-items: center
        justify-content: space-between
        padding: 1.5rem 1rem 1rem 1.5rem

        &-text
          @include F17Book
          color: var(--text900)
        &-button
          @include F13Bold
          color: var(--action600)
          padding: .45rem .85rem
          background: var(--inputRouteHighlight)
          border-radius: .8rem
      .benefits

        &__header
          display: flex
          align-items: center
          padding: 2rem 1.5rem 1rem 1.5rem

          &-title
            @include F20Medium
            color: var(--text900)
          &-line
            width: 100%
            height: .1rem
            background: var(--text100)
            margin: 0 1rem
          &-badage
            padding: .65rem .7rem
            background: var(--inputRouteHighlight)
            border-radius: .8rem
            @include F13Bold
            color: var(--action600)

        &__body
          display: grid
          grid-template-columns: 1fr 1fr
          grid-gap: 1rem
          margin: 0 1.5rem

          &--pc
            grid-template-columns: 1fr 1fr 1fr

          &-item
            display: flex
            flex-direction: column
            padding: 1rem
            border: .1rem solid var(--text50)
            border-radius: .6rem

            p
              @include F13Book
              color: var(--text900)
              margin-top: 1rem

        &__footer
          margin: 2.5rem 1.5rem 1.1rem 1.5rem

          &-title
            @include F20Medium
            color: var(--text900)
          &-ul
            margin-top: 1rem
            padding-bottom: 1.1rem
          &-li
            @include F15Book
            color: var(--text900)
            display: flex
            align-items: center
            padding: .5rem 2rem .5rem .5rem
            svg
              margin-right: 1.5rem

  &__contacts
    padding-bottom: 3rem
    &-description
      @include F15Book
      color: var(--text400)
      margin: 1rem 3.5rem 1rem 2.5rem

    &-form
      display: grid
      grid-template-columns: 1fr
      grid-gap: 1rem
      margin: 0 1rem

      .grid
        display: grid
        grid-gap: 1rem
        grid-template-columns: 20rem 1fr

  &__confirm
    display: flex
    align-items: center
    justify-content: center
    padding-bottom: 4rem

    &-text
      @include F13Bold
      color: var(--text900)
      margin-left: .9rem

  &__payment

    &-title
      margin-top: 4rem

    &-nav
      margin: 2rem 1rem 1rem 1rem
      button
        padding: 1rem 1.4rem
        color: var(--action500)
        @include F15Bold
        border-radius: 1rem
        &.active
          color: var(--white100)
          background: var(--text800)
          @include blackShadow

    &-form
      display: grid
      grid-template-columns: 1fr
      grid-gap: 1rem
      padding: 1rem

      &--two
        display: grid
        grid-template-columns: 1fr 1fr
        grid-gap: 1rem

    &-support
      background: var(--white100)
      @include E23
      border-radius: .6rem
      display: flex
      align-items: center
      justify-content: center
      grid-gap: 1rem
      padding: 1rem

      span
        @include F15Book
        color: var(--text900)

  &__billing
    margin-top: 5rem

    &-form
      display: grid
      grid-template-columns: 1fr
      grid-gap: 1rem
      padding: 1rem

  &__phone
    margin-top: 3rem
    &-form
      display: grid
      grid-template-columns: 1fr
      grid-gap: 1rem
      padding: 1rem

  &__transfer

    margin: 1.6rem 2.5rem 1.4rem 2.5rem

    &-title
      @include F17Book
      margin-bottom: .4rem

    &-item
      margin-top: 1.2rem
      .item
        &-title
          @include F15Book
          color: var(--text400)
        &-text
          @include F17Book
          color: var(--text900)
        &-info
          display: flex
          align-items: flex-start
        &-btn
          margin-left: 1.5rem

    &-line
      width: 100%
      height: .1rem
      background: var(--action300)
      margin: 1.6rem 0

    &-description
      @include F15Book
      color: var(--text400)
      margin-bottom: 1.6rem

.block
  display: flex
  align-items: center
  margin-top: 1.5rem
  margin-bottom: 1.5rem

  &__svg

    &--revers
      transform: rotateY(180deg)

  &-title
    @include F20Medium
    color: var(--text900)
    margin-left: 1.5rem

.pcContainer
  display: grid
  grid-template-columns: 1fr 49rem
  grid-gap: 3.5rem
  padding: 2.5rem 12rem 4.7rem 12rem
  &--full
    grid-template-columns: 1fr

  &__aside
    position: sticky
    top: 2.5rem
    border: .1rem solid var(--text100)
    border-radius: 1.2rem
    padding: 2rem
    text-align: center
    height: fit-content

    &-inputs
      display: grid
      grid-template-columns: 1fr
      grid-gap: 1rem

      .grid
        display: grid
        grid-template-columns: 13rem 1fr
        grid-gap: 1rem
        .phone
          border-radius: 0.4rem
          box-shadow: 0 0.1rem 0.6rem rgb(0 0 0 / 6%)
          background-color: var(--white100)
          height: 5.6rem
          display: flex
          justify-content: center
          align-items: center
          @include F15Book

    &-title
      @include F24Medium
      color: var(--text900)
    &-description
      @include F15Book
      color: var(--text500)

    &-price
      display: flex
      align-items: center
      justify-content: center
      margin-top: 1.5rem
      .text
        @include F20Bold
        color: var(--text900)
        margin-right: 1.8rem
      button
        width: 16rem

    &-agent
      display: flex
      flex-direction: column
      align-items: center
      margin-top: 11.1rem
      margin-bottom: 2.4rem

      .avatar
        width: 4.8rem
        height: 4.8rem
        object-fit: cover
        border-radius: 100%

      .name
        @include F15Bold
        color: var(--text500)
        margin-top: .8rem

      .phone
        @include F15Bold
        color: var(--text500)
        margin-top: .5rem
        display: flex
        align-items: center
        img
          margin-right: .5rem

      .mail
        @include F15Bold
        color: var(--action500)
        text-decoration: none
        margin-top: .5rem

    &-footer
      @include F13Book
      color: var(--text400)

  &__body

.pcBiling
  display: grid
  grid-template-columns: 1fr
  grid-gap: 1rem
  margin: 1rem 1rem 3rem 1rem

  &-form
    display: grid
    grid-template-columns: 1fr 1fr
    grid-gap: 1rem
  &-form2
    display: grid
    grid-template-columns: 1fr 1fr 1fr
    grid-gap: 1rem


.validationError
  display: flex
  align-items: center
  color: var(--additionalRed)
  @include F15Book
  svg
    margin-right: .8rem
    margin-left: .1rem