.tip

  border-radius: 1.7rem
  background: var(--additionalTipBg)
  padding: .8rem 1.5rem .8rem .8rem

  display: flex
  align-items: center
  width: fit-content

  &-text
    margin-left: .3rem
    font-family: 'CircularStd_Book'
    font-style: normal
    font-weight: normal
    font-size: 1.2rem
    line-height: 1.6rem
    color: var(--additionalTipText)

  &--multi
    max-width: 21.4rem
