@import '../../styles/inc/fonts'
@import '../../styles/inc/effects'
@import '../../styles/inc/consts'

.body__charge
  background: var(--white100)
  margin: 1rem
  border-radius: 1.2rem
  @include mailCardShadow

  &-info
    border-bottom: .1rem solid var(--text50)
    padding: 0 2.5rem
    padding-bottom: 1.5rem

  &-title
    @include F20Medium
    color: var(--text900)
    margin-bottom: 2rem
    padding: 2.5rem 2.5rem 0 2.5rem
    font-family: "CircularStd_Bold"
    text-transform: capitalize

  .line
    display: flex
    align-items: center
    justify-content: space-between

    &--spacer
      margin-bottom: 1.5rem

    &-title
      @include F13Book
      color: var(--text400)
      &--bold
        @include F15Medium
        color: var(--text900)
        margin-bottom: 1.5rem

    &-value
      @include F13Book
      color: var(--text400)
      &--bold
        @include F15Medium
        color: var(--text900)
        margin-bottom: 1.5rem

    &-button
      width: fit-content
      padding: .3rem .5rem
      border-radius: .6rem
      background: var(--action50)
      @include F12Medium
      color: var(--action500)
      display: flex
      align-items: center
      justify-content: center
      svg
        margin-right: .5rem

  &-footer
    display: flex
    align-items: center
    justify-content: space-between
    padding: 2rem 2.5rem 2.5rem 2.5rem

    .title
      @include F20Bold
      color: var(--text900)
    .value
      @include F24Medium
      color: var(--text900)

  &-confirm
    background: var(--inputRouteHighlight)
    border-radius: 0 0 1.2rem 1.2rem
    padding: 2rem 1.5rem 2.5rem 1.5rem
    display: grid
    grid-template-columns: 3.2rem 1fr
    align-items: flex-start
    grid-gap: .9rem

    .checkbox
      margin-top: 1rem

    .info

      @include F13Book
      color: var(--text600)

      text-align: justify

      b
        @include F13Bold
        color: var(--text900)
      a
        color: var(--action500)

.body__copyright

  text-align: center

  &-text

    margin-top: 2.5rem
    padding-bottom: 2rem

    color: var(--text400)
    display: flex
    flex-direction: column

    @include F13Book

    b
      color: var(--text600)


.pay
  width: 100%
  background: var(--inputRouteHighlight)
  border-radius: 0 0 1.2rem 1.2rem
  padding: 5rem 2.5rem 3rem 2.5rem
  button
    width: 100%