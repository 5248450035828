.container
  display: block
  position: relative
  cursor: pointer
  user-select: none
  height: 100%


  & input
    position: absolute
    opacity: 0
    cursor: pointer
    height: 0
    width: 0

.checkmark
  position: absolute
  top: 50%
  left: 0
  height: 2.4rem
  width: 2.4rem
  border: .2rem solid var(--action200)
  border-radius: 100%
  transition: .3s
  transform: translateY(-50%)


  .container input:checked ~ &
    background-color: var(--action500)
    border: .2rem solid var(--action500)


  &:after
    content: ""
    position: absolute
    display: none


  .container input:checked ~ &:after
    display: block


  .container &:after
    left: .9rem
    top: .5rem
    width: .4rem
    height: .8rem
    border: solid white
    border-width: 0 .3rem .3rem 0
    transform: rotate(45deg)
